<template>
  <v-app>
    <v-main class="bg-body" id="scroll-target">
      <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import BaseView from './View.vue';

export default {
  components: {
    BaseView
  },
  watch: {
    '$route.params.entidadId': {
      immediate: true,
      handler() {
        this.fetchColorTheme();
      },
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  methods: {
    fetchColorTheme() {
      const entidadId = this.$route.params.entidadId;

      this.isLoading = true;

      if (entidadId) {
        axios.get(`api/v1/entities/${entidadId}/theme`)
          .then((res) => {
            const payload = _.get(res, 'data.data', {});

            const defaultTheme = this.$vuetify.theme.defaults.light;

            const colorTheme = {
              primary: payload.primaryColor || defaultTheme.primary,
              secondary: payload.secondaryColor || defaultTheme.secondaryr,
              accent: payload.accentColor || defaultTheme.accent
            }

            _.merge(this.$vuetify.theme.themes.light, colorTheme);

          })
          .catch(() => {
            // DOES NOTHING
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }

    }
  }
};
</script>
